import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

import Main from '../../../components/Blog/Selecting-A-Software-Company/Main'

const BlogPage = () => (
  <Layout>
    <SEO title="Selecting a Software Company" />

    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Learn how we can transform your expectations of custom software." />
      <title>Selecting a Software Company | LaMar Software</title>
      <link rel="canonical" href="https://lamarsoftware.io/blog/selecting-a-software-company" />
    </Helmet>

    <Main />
  </Layout>
)

export default BlogPage
