import React from 'react'

import {
  MainShape,
  StyledContainer,
  StyledH2,
  StyledLink,
  StyledP,
  StyledSpan,
  BlogContainer,
  SectionHeading,
  SectionContent,
} from './Main.style'

import Grid from '@material-ui/core/Grid'
import { Link } from 'gatsby'

const Main = () => (
  <Grid container>
    <MainShape item>
      <StyledContainer>
        <StyledH2>Selecting a Software Company</StyledH2>
        <StyledP>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/blog"> / Blog</StyledLink>
          <StyledSpan> / Selecting a Software Company</StyledSpan>
        </StyledP>
      </StyledContainer>
    </MainShape>

    <BlogContainer>
      <SectionHeading>Preface</SectionHeading>
      <SectionContent>We at LaMar Software value transparency </SectionContent>

      <SectionHeading>Expect Transparent Communication</SectionHeading>
      <SectionContent>asdf asdf asdf asdf </SectionContent>

      <SectionHeading>Expect You to Own the IP</SectionHeading>
      <SectionContent>asdf asdf asdf asdf </SectionContent>
    </BlogContainer>
  </Grid>
)

export default Main
